import styled, { css } from "styled-components";
import { FlexRow, P, Icon } from "notes";
import { useFanEventContext } from ".";

const ViewPropTypes = [
  "requests",
  "playlist",
  "sponsor",
  "encore",
  "html",
  "checkIn",
] as const;

export type ViewProps = typeof ViewPropTypes[number];

interface NavProps {
  iconActive: any;
  iconInactive: any;
  label: string;
  name: ViewProps;
}

export const Navigation = () => {
  const { setView, view, event } = useFanEventContext();

  let navItems: NavProps[] = [
    {
      iconActive: <Icon form name="Record" />,
      iconInactive: <Icon form name="Record" />,
      label: event.customizations?.catalog?.tab ?? "Catalog",
      name: "playlist",
    },
    {
      iconActive: <Icon tertiary name="Music" />,
      iconInactive: <Icon form name="Music" />,
      label: event.customizations?.vote?.tab ?? "Top Requests",
      name: "requests",
    },
  ];

  if (event?.type === "encore") {
    const hasCustomTab = !!Object.keys(event?.customTab ?? {})?.length;
    if (hasCustomTab) {
      const tabIcon = event?.customTab?.tabIcon;
      navItems = [
        {
          iconActive: <Icon tertiary name="Music" />,
          iconInactive: <Icon form name="Music" />,
          label: event.customizations?.encore?.tab ?? "Fan Favorite",
          name: "encore",
        },
        {
          label: event?.customTab?.tabLabel ?? "Merch",
          iconActive: tabIcon?.darkPath ? (
            <img src={tabIcon?.darkPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          iconInactive: tabIcon?.lightPath ? (
            <img src={tabIcon?.lightPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          name: "html",
        },
      ];
    } else {
      return null;
    }
  }

  if (event?.type === "checkIn") {
    const hasCustomTab = !!Object.keys(event?.customTab ?? {})?.length;
    if (hasCustomTab) {
      const checkIn = event?.checkIn;
      const tabIcon = event?.customTab?.tabIcon;
      navItems = [
        {
          iconActive: <Icon tertiary name="Edit" />,
          iconInactive: <Icon tertiary name="Edit" />,
          label: checkIn?.tab ?? "Check In",
          name: "checkIn",
        },
        {
          label: event?.customTab?.tabLabel ?? "Merch",
          iconActive: tabIcon?.darkPath ? (
            <img src={tabIcon?.darkPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          iconInactive: tabIcon?.lightPath ? (
            <img src={tabIcon?.lightPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          name: "html",
        },
      ];
    } else {
      return null;
    }
  }

  if (event?.type === "sponsor") {
    const hasEncore = !!Object.keys(event?.encore ?? {})?.length;
    if (hasEncore) {
      navItems = [
        {
          iconActive: <Icon form name="Record" />,
          iconInactive: <Icon form name="Record" />,
          label: event.customizations?.sponsor?.tab ?? "Sponsor",
          name: "sponsor",
        },
        {
          iconActive: <Icon tertiary name="Music" />,
          iconInactive: <Icon form name="Music" />,
          label: event.customizations?.encore?.tab ?? "Fan Favorite",
          name: "encore",
        },
      ];
    } else {
      return null;
    }
  }

  return (
    <Container>
      <OptionWrapper>
        {navItems.map(({ label, name, iconActive, iconInactive }) => (
          <Option
            key={name}
            active={view === name}
            onClick={() => setView(name)}
          >
            {view === name ? iconActive : iconInactive}
            <P>{label}</P>
          </Option>
        ))}
      </OptionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.branding.event.colors.page};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
`;

const OptionWrapper = styled(FlexRow)`
  background-color: ${(props) =>
    props.theme.branding.event.colors.tabs.inactiveBackground};
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 14px;
    height: 14px;
  }
`;

const Option = styled(FlexRow)`
  background-color: ${(props) =>
    props.active
      ? props.theme.branding.event.colors.tabs.activeBackground
      : "transparent"};
  ${(props) =>
    props.active
      ? css`
          &:first-child {
            border-radius: 0 20px 20px 0;
          }
          &:last-child {
            border-radius: 20px 0 0 20px;
          }
        `
      : css`
          &:last-child {
            border-radius: 0 20px 20px 0;
          }
          &:first-child {
            border-radius: 20px 0 0 20px;
          }
        `};
  cursor: pointer;
  padding: 0 16px;
  position: relative;
  min-width: 154px;
  height: 40px;
  justify-content: center;
  align-items: center;
  svg {
    width: 14px;
    height: 14px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.branding.event.colors.tabs.activeText
          : props.theme.branding.event.colors.tabs.inactiveText};
    }
  }
  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.branding.event.colors.tabs.activeText
        : props.theme.branding.event.colors.tabs.inactiveText};
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-left: 8px;
  }
`;
