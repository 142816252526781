import styled from "styled-components";
import { FlexColumn, FlexRow, H3, H4, Icon, P, Small } from "notes";
import { getDateOrdinal } from "Utils";
import { useArtistEventContext } from ".";

export const Banner = () => {
  const { event, requestsTotalCents, encorePaymentAllowed, requests } =
    useArtistEventContext();
  const { image, venue, startsAt, endedAt, imageBanner, type } = event ?? {};
  const eventHasNoSupport = type === "encore" && !encorePaymentAllowed;
  return (
    <Wrapper yEnd flex="0 0 auto">
      <Content>
        <H3>{venue}</H3>
        <Divider />
        <H4>
          {startsAt?.toFormat("cccc, LLLL d")}
          {getDateOrdinal(startsAt?.day)} ({startsAt?.toFormat("h:mm")}
          <span>{startsAt?.toFormat("a").toLowerCase()}</span>
          {startsAt?.toFormat("ZZZZ")})
        </H4>
        <FlexRow style={{ justifyContent: "space-between" }} yEnd>
          <Support yCenter>
            {type === "checkIn" ? (
              <>
                <Icon tertiary name="Description" /> <P>{requests?.length}</P>
                <Small>{requests?.length === 1 ? "Entry" : "Entries"}</Small>
              </>
            ) : !eventHasNoSupport ? (
              <>
                <Icon tertiary name="Charity" /> <P>${requestsTotalCents}.00</P>
                <Small>Total Support</Small>
              </>
            ) : (
              <>
                <Icon
                  style={{ width: "14px", height: "14px", marginTop: "-4px" }}
                  tertiary
                  name="Trophy"
                />{" "}
                <P>{requests?.length ?? 0}</P>
                <Small>Total Votes</Small>
              </>
            )}
          </Support>
          {endedAt && <EndShowBadge>Show Ended</EndShowBadge>}
        </FlexRow>
      </Content>
      <Gradient />
      <Image image={imageBanner || image} />
    </Wrapper>
  );
};

const Support = styled(FlexRow)`
  margin-top: 16px;
  color: #ffffff;
  ${P} {
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    margin-left: 8px;
  }
  ${Small} {
    color: #ffffff;
    font-weight: 600;
    margin-left: 6px;
    line-height: 20px;
  }
  svg {
    margin-bottom: 2px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${P} {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;

const Divider = styled.div`
  height: 2px;
  width: 156px;
  background-color: #ff8c00;
  flex-shrink: 0;
  margin-bottom: 8px;
`;

const Wrapper = styled(FlexColumn)`
  background: #000000;
  padding: 62px 16px 16px 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 60;
  ${H3} {
    color: #ffffff;
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  ${H4} {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    span {
      margin-right: 4px;
    }
  }
`;

const Content = styled(FlexColumn)`
  position: relative;
  padding: 62px 16px 16px 16px;
  z-index: 3;
  width: 100%;
  max-width: 600px;
  align-self: center;
`;

const Gradient = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

interface Props {
  image: string;
}

const Image = styled.div<Props>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  filter: blur(6px);
  margin: -8px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  z-index: 1;
`;

const EndShowBadge = styled(FlexColumn)`
  align-items: center;
  background: ${(props) => props.theme.palette.red.primary};
  color: #000000;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-transform: uppercase;
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.red.primary};
`;
