import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { FlexRow, FlexColumn, Icon, Link } from "notes";
import { LogoWhite, LogoGradient, MenuDefault, MenuExpanded } from "Images";
import { IconWrapper } from "./Layout";
import { useAppContext, useBrandingContext } from "Context";

interface Props {
  children?: any;
  secondary?: boolean;
  tertiary?: boolean;
  isArtist?: boolean;
  showExitLink?: boolean;
  hideLogo?: boolean;
}

export const Header = ({
  children,
  secondary,
  tertiary,
  isArtist,
  showExitLink = false,
  hideLogo = false,
  ...props
}: Props) => {
  const history = useHistory();
  const { setAllowSearch, setIsSideDrawerOpen, isSideDrawerOpen } =
    useAppContext();
  const { brand, logo } = useBrandingContext();
  return (
    <Wrapper
      secondary={secondary}
      tertiary={tertiary}
      isArtist={isArtist}
      {...props}
    >
      <Container xCenter yCenter secondary={secondary}>
        {isArtist &&
          (secondary ? (
            <IconWrapper onClick={() => history.push("/artist")}>
              <Icon tertiary name="Left" />
            </IconWrapper>
          ) : (
            <MenuIconWrapper
              onClick={() => setIsSideDrawerOpen(!isSideDrawerOpen)}
            >
              {isSideDrawerOpen ? <MenuExpanded /> : <MenuDefault />}
            </MenuIconWrapper>
          ))}
        <FlexRow yCenter flex={secondary || tertiary ? "1 0 auto" : "0 0 auto"}>
          {!hideLogo &&
            (tertiary ? (
              <LogoGradient />
            ) : logo ? (
              <img src={logo} alt={brand} />
            ) : (
              <LogoWhite />
            ))}
        </FlexRow>
        {showExitLink && (
          <Exit
            onClick={() => {
              history.push(isArtist ? "/artist" : "/");
              !isArtist && setAllowSearch(false);
            }}
          >
            Exit Show
          </Exit>
        )}
        {!tertiary && !isArtist && (
          <Help
            secondary={secondary}
            indicator
            name="Questions"
            onClick={() => history.push("/contact", { hasHistory: true })}
          />
        )}
        {children}
      </Container>
    </Wrapper>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  max-width: 600px;
  padding: 0 16px;
  position: relative;
  ${(props) =>
    props.secondary &&
    css`
      justify-content: space-between;
    `};
`;

const Exit = styled(Link)`
  color: ${(props) => props.theme.branding.event.colors.headerActions};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-right: 24px;
  &:hover {
    color: ${(props) => props.theme.branding.event.colors.headerActions};
  }
`;

const Help = styled(Icon)`
  position: absolute;
  right: 24px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  path {
    fill: ${(props) =>
      props.theme.branding[props.secondary ? "event" : "lobby"].colors
        .headerActions};
  }
`;

const MenuIconWrapper = styled(FlexColumn)`
  color: #222222;
  width: 16px;
  height: 16px;
  margin-right: 24px;
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  background: ${(props) => props.theme.branding.lobby.colors.header};
  color: #ffffff;
  z-index: 100;
  height: 40px;
  img {
    max-width: 80px;
  }
  ${(props) =>
    props.secondary &&
    css`
      background: transparent;
      justify-content: space-between;
      top: 0;
      left: 0;
      position: absolute;
      ${Help} {
        position: relative;
        right: initial;
      }
    `};
  ${(props) =>
    props.tertiary &&
    `
    background: #ffffff;
    border-bottom: 1px solid ${props.theme.palette.gray.lightest};
    justify-content: space-between;
  `};

  ${(props) =>
    props.secondary &&
    props.isArtist &&
    css`
      height: 56px;
    `};
  @media only screen and ${(props) => props.theme.media.medium} {
    justify-content: center;
  }
`;
