import { Icon, Subheader, H3, H4, FlexRow, FlexColumn } from "notes";
import { EventCard, useArtistModalContext } from "../Components";
import { useCollectionModel } from "Hooks/models";
import { collection, query, where } from "firebase/firestore";
import { firestore } from "firebase-internal";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import styled from "styled-components";
import { Avatar } from "../QRCodes";
import { useHistory } from "react-router-dom";
import { useUser } from "auth";
import { useAuthContext } from "Context";

export const Landing = () => {
  const {
    user,
    claims: { isAdmin },
  } = useUser();
  const { isArtist } = useAuthContext();
  const { eventView, groupIdView, setGroupIdView } = useArtistModalContext();
  const history = useHistory();
  let q = query(
    collection(firestore, "nextsong_events"),
    where("status", "!=", "deleted")
  );
  if (isArtist && !isAdmin)
    q = query(q, where("memberUids", "array-contains", user?.uid));

  const [e] = useCollectionModel(nextsong.firestore.Event, q);
  const formattedEvents =
    e
      ?.map((event) => ({ ...event, startsAt: event.startsAt.toLocal() }))
      ?.sort((a, b) => {
        return a.startsAt > b.startsAt ? 1 : -1;
      }) ?? [];

  const upcomingEvents = formattedEvents?.filter(({ endedAt }) => !endedAt);
  const pastEvents = formattedEvents?.filter(({ endedAt }) => endedAt);
  const isUpcoming = eventView === "upcoming";
  const eventsFilteredByTime = isUpcoming ? upcomingEvents : pastEvents;

  const uniqueEventGroups = eventsFilteredByTime?.reduce(
    (map, { groupId, artistName, image }) => {
      const events = eventsFilteredByTime?.filter((i) => i.groupId === groupId);
      if (!map[groupId])
        map[groupId] = {
          artistName,
          image,
          events,
        };
      return map;
    },
    {}
  );

  const hasGroups =
    !!uniqueEventGroups &&
    !!Object.keys(uniqueEventGroups) &&
    Object.keys(uniqueEventGroups)?.length > 1;

  const selectedGroup = uniqueEventGroups[groupIdView];
  const isGroupView = !groupIdView && hasGroups;
  const events = groupIdView ? selectedGroup?.events : eventsFilteredByTime;

  return (
    <>
      <PageTitle yCenter>
        <FlexRow yCenter>
          <IconWrapper xCenter yCenter>
            {isGroupView || !hasGroups ? (
              <Icon tertiary={isUpcoming} form={!isUpcoming} name="Calendar" />
            ) : (
              <ArrowIcon
                tertiary
                onClick={() => setGroupIdView(null)}
                name="Left"
              />
            )}
          </IconWrapper>

          <H3>{isUpcoming ? "Upcoming Shows" : "Past Shows"}</H3>
        </FlexRow>
        {hasGroups && (
          <Subheader>
            {isGroupView ? "Artists" : selectedGroup?.artistName}
          </Subheader>
        )}
      </PageTitle>
      {isGroupView
        ? Object.keys(uniqueEventGroups)?.map((groupId) => {
            const { artistName, image } = uniqueEventGroups[groupId];
            return (
              <Group
                key={groupId}
                yCenter
                onClick={() => setGroupIdView(groupId)}
              >
                <Avatar src={image} />
                <H4>{artistName}</H4>
                <Icon indicator name="Closed" />
              </Group>
            );
          })
        : events?.map((event) => (
            <EventCard
              key={event._id}
              event={event}
              onClick={() => history.push(`/artist/${event._id}`)}
            />
          ))}
    </>
  );
};

const Group = styled(FlexRow)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 10px 24px 10px 0;
  margin-right: -24px;
  cursor: default;
  user-select: none;
  ${H4} {
    font-size: 15px;
    font-weight: 600;
    flex-grow: 1;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-right: 0;
    padding: 12px 12px 12px 0;
    ${H4} {
      font-size: 17px;
      line-height: 24px;
    }
  }
`;

const PageTitle = styled(FlexColumn)`
  margin-bottom: 16px;
  ${H3} {
    font-size: 20px;
    font-family: ${(props) => props.theme.branding.headerFont};
    line-height: 24px;
    text-transform: uppercase;
  }
  ${Subheader} {
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 44px;
    text-transform: uppercase;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${H3} {
      font-size: 25px;
      line-height: 32px;
    }
    ${Subheader} {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const IconWrapper = styled(FlexColumn)`
  background: #f5f5f5;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: -8px 8px -8px 0;
  margin-right: 8px;
  svg {
    color: ${(props) => props.theme.palette.black};
  }
`;

const ArrowIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin: -2px;
`;
