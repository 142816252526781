import haversine from "Utils/haversine";
import { useAuthContext, useBrandingContext } from "Context";
import { firestore } from "firebase-internal";
import { collection, where, query } from "firebase/firestore";
import type { Coordinates } from "Hooks/useLocation";
import { useCollectionModel } from "Hooks/models";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import type { DocumentModel } from "Hooks/models";
import { useUser } from "auth";
export type EventModel = DocumentModel<nextsong.firestore.Event>;

export const useEventList = (
  location?: Coordinates,
  isArtistView: boolean = false
) => {
  const {
    user,
    claims: { isAdmin },
  } = useUser();
  const { isArtist } = useAuthContext();
  const { groupId, brandingId } = useBrandingContext();

  let q = query(collection(firestore, "nextsong_events"));

  if (isArtistView && isArtist && !isAdmin) {
    q = query(q, where("memberUids", "array-contains", user?.uid));
  }
  if (!isArtist && !isAdmin) {
    q = query(
      q,
      where("endedAt", "==", null),
      where("status", "==", "published")
    );
  }
  if (!isArtist && !isAdmin && groupId) {
    q = query(
      q,
      where("endedAt", "==", null),
      where("groupId", "==", groupId),
      where("status", "==", "published")
    );
  }
  if (!isArtist && !isAdmin && brandingId && !groupId) {
    q = query(
      q,
      where("endedAt", "==", null),
      where("brandingId", "==", brandingId),
      where("status", "==", "published")
    );
  }

  const [eventsWithinTimeframe, eventsLoading] = useCollectionModel(
    nextsong.firestore.Event,
    q
  );

  let timeSortedEvents: EventModel[] = eventsWithinTimeframe
    ?.map((event) => ({ ...event, startsAt: event.startsAt.toLocal() }))
    ?.sort((a, b) => {
      return a.startsAt > b.startsAt ? 1 : -1;
    });

  if (location && Object.keys(location).length === 2) {
    timeSortedEvents = eventsWithinTimeframe
      ?.map((event) => {
        const distance = haversine(event.coordinates, location, {
          unit: "meter",
        });
        return { ...event, distance };
      })
      .sort((a, b) => {
        return a.distance > b.distance ? 1 : -1;
      });
  }

  return { events: timeSortedEvents, eventsLoading };
};
