import { FC, createContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import {
  getAnalytics,
  logEvent,
  setUserId,
  Analytics,
} from "firebase/analytics";
import app from "firebase-internal";
import { useUser } from "auth";

interface LogClickParams {
  label: string;
  url: string;
}

interface ContextInterface {
  logClick: (logClickParams: LogClickParams) => void;
}

export const AnalyticsContext = createContext<ContextInterface>(null);

export const AnalyticsProvider: FC = ({ children }) => {
  const { user } = useUser();

  const analytics: Analytics = getAnalytics(app);

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || "GTM-KLZ7VPV",
      dataLayer: {
        event: "analytics_init",
        max_params: {
          googleAnalyticsId:
            process.env.REACT_APP_GA_UNIVERSAL_ID || "UA-210743984-1",
        },
      },
    });
  }, []);

  useEffect(() => {
    if (user?.uid) {
      setUserId(analytics, user.uid);
    }
  }, [user, analytics]);

  const logClick = ({ label, url }) => {
    internalLogEvent({
      eventName: "click",
      eventParams: {
        label,
        url,
      },
    });
  };

  const internalLogEvent = ({ eventName, eventParams = {} }) => {
    logEvent(analytics, eventName, eventParams);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        logClick,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
