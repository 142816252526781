import styled from "styled-components";
import {
  Footer as FooterBase,
  Header,
  Map,
  Button,
  ButtonSecondary,
} from "Components";
import { useHistory } from "react-router-dom";
import { FlexColumn, H3, H4, P, Small, Subheader, Link, Avatar } from "notes";
import { MapIcon } from "Images";
import { getDateOrdinal } from "Utils";
import { AnalyticsContext, useAppContext, useBrandingContext } from "Context";
import { useContext } from "react";
import { useFanEventContext, useFanModalContext } from "./Components";

export const Lobby = () => {
  const { event } = useFanEventContext();
  const { setModalState } = useFanModalContext();
  const { setAllowSearch } = useAppContext();
  const {
    lobby,
    footer: { termsOfUseUrl },
  } = useBrandingContext();
  const { logClick } = useContext(AnalyticsContext);
  const {
    _id: eventId,
    artistName,
    startsAt,
    venue,
    image,
    coordinates,
  } = event;
  const history = useHistory();

  const onJoinClickHandler = () => {
    const url = `/event/${eventId}`;
    logClick({
      label: `Join Show - ${artistName}`,
      url,
    });
    history.push(url);
  };

  const onֿֿDiffShowClickHandler = () => {
    logClick({
      label: `Different Show - ${artistName}`,
      url: window.location.href,
    });
    setAllowSearch(false);
    history.push("/");
  };

  return (
    <Wrapper>
      <Header />
      {!lobby?.splashImage ? (
        <Map center={coordinates} />
      ) : (
        <>
          <MapImage image={lobby?.splashImage} />
          <FlexColumn flex="0 0 128px" />
        </>
      )}
      <ResultContent flex="1 1 auto">
        {!lobby?.disableAvatar ? (
          <StyledAvatar src={image} />
        ) : (
          <FlexColumn flex="0 0 80px" />
        )}
        {!lobby?.disableText ? (
          <>
            <Subheader>Are You Here To See</Subheader>
            <H3>{artistName}</H3>
            <H4>
              <IconMap /> {venue}
            </H4>
            <P>
              {startsAt?.toFormat("cccc, LLLL d")}
              {getDateOrdinal(startsAt?.day)}
              <br />
              {startsAt?.toFormat("h:mm")}
              <span>{startsAt?.toFormat("a")?.toLowerCase()}</span>{" "}
              {startsAt?.toFormat("ZZZZ")}
            </P>
          </>
        ) : (
          <FlexColumn flex="0 0 140px" />
        )}
        <Actions>
          <Button onClick={onJoinClickHandler}>{lobby?.joinButton}</Button>
          <ButtonSecondary
            onClick={onֿֿDiffShowClickHandler}
            style={{ marginTop: "8px" }}
          >
            {lobby?.cancelButton}
          </ButtonSecondary>
        </Actions>
        <Small>
          By joining the show, you agree to our{" "}
          <Link
            onClick={() => {
              termsOfUseUrl.startsWith("/")
                ? setModalState({ tos: true })
                : window.open(termsOfUseUrl, "_blank");
            }}
          >
            terms of use
          </Link>
        </Small>
      </ResultContent>
      <Footer style={{ width: "100%" }} />
    </Wrapper>
  );
};

const Footer = styled(FooterBase)`
  background-color: ${(props) => props.theme.branding.lobby?.colors?.page};
`;

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  background-color: ${(props) => props.theme.branding.lobby?.colors?.page};
  align-items: center;
`;

const MapImage = styled(FlexColumn)`
  background: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: absolute;
  top: 40px;
  left: 0;
  height: 100%;
  width: 100%;
`;

const StyledAvatar = styled(Avatar)`
  align-self: center;
  border: 1.25px solid #e6e9eb;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin-top: -58px;
  margin-bottom: 40px;
  height: 100px;
  width: 100px;
  flex-shrink: 0;
`;

const ResultContent = styled(FlexColumn)`
  padding: 0 24px 24px 24px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
  ${Subheader}, ${H3} {
    margin-bottom: 8px;
  }
  ${Subheader} {
    color: ${(props) => props.theme.branding.lobby.colors.label};
  }
  ${H3} {
    color: ${(props) => props.theme.branding.lobby.colors.artist};
    font-family: ${(props) => props.theme.branding.bodyFont};
    font-weight: 800;
  }
  ${H4} {
    color: ${(props) => props.theme.branding.lobby.colors.venue};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => props.theme.branding.lobby.colors.date};
  }
  ${Small} {
    color: ${(props) => props.theme.branding.lobby.colors.legal};
    font-size: 11px;
    line-height: 14px;
    margin-top: 16px;
    ${Link} {
      font-size: 11px;
      line-height: 14px;
      text-decoration: underline;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 392px;
  }
`;

const Actions = styled(FlexColumn)`
  margin-top: 56px;
  flex-shrink: 0;
`;

const IconMap = styled(MapIcon)`
  color: ${(props) => props.theme.palette.red.primary};
  margin-right: 5px;
  flex: 0 0 19px;
  width: 19px;
  height: 19px;
`;
