import { FormField } from "@musicaudienceexchange/nextsong-interface/lib/esm/firestore/customizations";

export const defaultSponsorOptions = [
  { id: 1, text: "Happy Birthday!" },
  {
    id: 2,
    text: "Happy Anniversary!",
  },
  {
    id: 3,
    text: "Thanks for being a friend!",
  },
  {
    id: 4,
    text: "Love You!",
  },
  {
    id: 5,
    text: "Will you marry me?",
  },
  {
    id: 6,
    text: "Remember us listening to this one?",
  },
  {
    id: 7,
    text: "Hang in there!",
  },
  {
    id: 8,
    text: "Congrats!",
  },
];

export const defaultLeadFields: FormField[] = [
  {
    order: 1,
    label: "Name",
    name: "name",
    placeholder: "First and last name...",
    required: true,
    type: "text",
  },
  {
    order: 2,
    label: "Email address",
    name: "email",
    placeholder: "john@email.com",
    required: true,
    type: "email",
  },
  {
    order: 3,
    label: "Phone number",
    name: "phone",
    required: true,
    type: "phone",
  },
  {
    order: 4,
    label: "Zip code",
    name: "zipcode",
    placeholder: "5-digit code...",
    required: true,
    type: "zipcode",
  },
  {
    order: 5,
    label:
      "I agree to share the above information with {artist} and Set.Live <a href='https://www.max.live/privacy-policy' target='_blank'>Privacy Policy</a>",
    name: "consent",
    required: true,
    type: "checkbox",
  },
];
